body { margin: 0px; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; -webkit-font-smoothing: antialiased; }
code { font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }
.navbar { background-color: rgb(0, 165, 208); padding: 12px; display: flex; justify-content: space-between; align-items: center; }
h3 { margin: 0px; font-size: 24px; }
a { color: rgb(255, 255, 255); text-decoration: none; font-size: 16px; font-weight: 600; margin: 0px 0px 0px 12px; }
li, p { padding: 0px 0px 0px 8px; }
p { font-weight: 600; }
.Purchased { text-decoration: line-through; }
body { -webkit-font-smoothing: antialiased; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; margin: 0px; }
code { font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }
body { background-color: rgb(0, 0, 0); font-family: monospace; }
.container { height: 100vh; margin-top: 20px; }
.container, .content { align-items: center; display: flex; flex-direction: column; justify-content: center; }
.content { color: rgb(250, 250, 250); text-align: center; }
h1 { color: rgb(181, 181, 181); font-size: 150%; }
h1, h2 { margin-top: 20px; }
h2 { color: rgb(250, 250, 250); font-size: 100%; max-width: 90%; }
p { color: rgb(255, 255, 255); margin-top: 20px; }
.tutorial { align-items: center; justify-content: center; text-align: center; }
.logo { height: auto; width: 180px; }
.logoBingo { height: auto; width: 200px; }
.recover-access-image, .whatsapp-image { height: auto; width: 170px; }
.button-bottom { align-items: center; justify-content: center; }
.button-recover, .button-whats { background: none; border: none; cursor: pointer; outline: none; }
.button-whats { margin-right: -20px; }
.button { margin-top: 10px; }
.input-button-container { align-items: center; display: flex; margin-bottom: 30px; margin-top: 20px; }
.input-button-container input { border: 1px solid rgb(204, 204, 204); border-radius: 5px; margin-right: 10px; padding: 10px; width: 250px; }
.input-button-container button { background-color: rgb(240, 240, 240); border: none; border-radius: 5px; color: rgb(0, 0, 0); cursor: pointer; font-weight: 700; padding: 10px 20px; }
.input-button-container button:hover { background-color: rgb(240, 240, 240); }